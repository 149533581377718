"use client";
import React, { useEffect, useState } from "react";
import { Input } from "../components/ui/input";
import { cn } from "../lib/utils";
import ButtonHover from "./ButtonHover";
import FineRecaptcha from "./FineRecaptcha";
import axios from "axios";

const SubmitEmailForm = () => {
  const [captchaResp, setCaptcha] = useState("");
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const [email, setEmail] = useState("");
  const [disabledButton, setDisabledButton] = useState(true);

  useEffect(() => {
    if ( email && /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email) ) {
        setDisabledButton(false);
    } else {
        setDisabledButton(true);
    }
}, [email]);

  const encode = (data: any) => {
    return Object.keys(data)
        .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const result = await axios({
            validateStatus: function (status) {
                return true;
            },
            method: "post",
            url: "/submitemail",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            data: encode({
                "form-name": "submitemailform",
                "g-recaptcha-response": captchaResp,
                email,
            }),
        });
        if (result.status >= 200 && result.status <= 303) {
            window.location.href = "/success";
            return;
        }
        setRefreshReCaptcha(!refreshReCaptcha);
        alert(
            "Sorry, we could not reach servers, error occurred, please try after some time.",
        );
    };

  return (
    <div className="max-w-lg w-[300px] mx-auto rounded-none md:rounded-2xl shadow-input">
      <form 
        className="my-8" 
        name="submitemailform" 
        onSubmit={handleSubmit}
        data-netlify="true"
		data-netlify-recaptcha="true"
        action="/submitemail"
		method="post"
      >
        <div className="flex space-y-2 md:space-y-0 md:space-x-2 mb-4">
          <LabelInputContainer>
            <Input 
                name="email" 
                className="w-[250px]" 
                id="firstname"
                placeholder="Enter your email" 
                type="email" 
                value={email} 
                onChange={(e) => setEmail(e.target.value)}
            />
            <div className="ml-4"><ButtonHover disabledButton={disabledButton}/></div>
          </LabelInputContainer>
          <FineRecaptcha handleCaptcha={setCaptcha} refreshReCaptcha={refreshReCaptcha} />
        </div>
      </form>
    </div>
  );
}

const LabelInputContainer = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={cn("flex flex-row space-y-2 w-full", className)}>
      {children}
    </div>
  );
};

export default SubmitEmailForm;
