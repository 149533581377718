import '../App.css';

function Success() {
  return (
    <div className={`relative flex flex-col items-center justify-center h-screen overflow-hidden font-jura`}>
      <video
        playsInline
        autoPlay 
        loop 
        muted 
        className="absolute z-10 w-auto min-w-full min-h-full max-w-none"
      >
        <source src="https://fluidcloud.b-cdn.net/videos/compressed/fluidvideo.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="relative z-30 text-2xl text-white bg-opacity-50 rounded-xl">
        <div className="w-[400px] flex flex-row justify-center">
          <img src="fluidcloud_new.png" alt="fluidcloud_text" />
        </div>
      </div>
      <div className="relative z-30 p-5 text-2xl text-white bg-opacity-50 rounded-xl">
        <p>A stealth mode startup</p>
      </div>
      <div className="relative z-30 text-lg text-white bg-opacity-50 rounded-xl">
        <p>Thank you for contacting us</p>
      </div>
      <div className="relative z-30 text-lg text-white bg-opacity-50 rounded-xl mt-4">
        <p>We will keep you posted!</p>
      </div>  
    </div>
  );
}

export default Success;
