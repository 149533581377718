import React from "react";
import { GoogleReCaptcha, GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const FineRecaptcha = (props: any) => {
    const RECAPTCHA_KEY = '6LdhHlgqAAAAAL293ZuMENlk_OFYgMVqCZ08msxD';
    const { handleCaptcha, refreshReCaptcha } = props;
    return (
        <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
          <GoogleReCaptcha
            onVerify={handleCaptcha}
            refreshReCaptcha={refreshReCaptcha}
          />
        </GoogleReCaptchaProvider>
    )
};

export default FineRecaptcha;
